<template>
  <div class="liveLottery">
    <div v-title data-title="新春首存"></div>

    <van-nav-bar title="新春首存" left-arrow :fixed="true" @click-left="gotoback" />

    <div class="imgbox1">
      <img :src="`${imgUrl}pcbb/bg.png`" />
    </div>
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-newsyeardeposit.png`" />

      <!-- <img src="../assets/img/pcbb/bg-RealSuperGold.png" /> -->
    </div>

    <div class="activityRules" style="padding-bottom: 15px">
      <div class="activity_rules">
        <ul>
          <p style="
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #303030;
                      font-size: 13px;
                    ">
            新春巨惠送大礼，春节活动期间每天首笔存款送彩金，存得越多送得越多。
          </p>
        </ul>
      </div>

      <table style="margin-top: 10px">
        <thead>
          <tr style="background-color: #f6e9db">
            <th style="
                        color: #684728;
                        border-top-left-radius: 10px;
                        border-right: 2px solid #e2d2bf;
                      ">
              首存金额
            </th>
            <th style="color: #684728; border-top-right-radius: 10px">
              最高返还
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>500元</td>
            <td>68元</td>
          </tr>
          <tr>
            <td>1000元</td>
            <td>88元</td>
          </tr>
          <tr>
            <td>3000元</td>
            <td>138元</td>
          </tr>
          <tr>
            <td>5000元</td>
            <td>188元</td>
          </tr>
          <tr>
            <td>10000元</td>
            <td>288元</td>
          </tr>
          <tr>
            <td>20000元</td>
            <td>388元</td>
          </tr>
          <tr>
            <td>30000元</td>
            <td>688元</td>
          </tr>
          <tr>
            <td>50000元</td>
            <td>888元</td>
          </tr>
        </tbody>
      </table>

      <div style="
                  color: #000;
                  font-size: 13px;
                  margin: 10px 15px;
                  margin-bottom: 0;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #e93f3f;
                ">
        活动时间:2022.02.01 - 2022.02.07 ，大年初一 至
        大年初七活动期间每日完成首笔存款即可找客服领取
      </div>

      <div class="btn-box">
        <div @click="applyWay"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  methods: {
    gotoback () {
      if (window.localStorage.getItem("opentype") == "h5") {
        uni.navigateBack({});
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: "true",
            },
          });
        });
      }
    },

    applyWay () {
      uni.getEnv((res) => {
        if (window.localStorage.getItem("opentype") == "h5") {
          uni.switchTab({ url: "/pages/service/service" });
        } else {
          uni.getEnv((res) => {
            uni.postMessage({
              data: {
                apptabUrl: "/pages/service/service",
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="less">
.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f5f5f5;
}

.liveLottery {
  .imgbox {

    // margin: 10px 20px;
    // border-radius: 8px;
    img {
      // border-radius: 8px;
      width: 100%;
    }
  }

  .imgbox1 {
    width: 100%;
    position: absolute;

    img {
      width: 100%;
      background-size: 100% 100%;
      position: absolute;
      z-index: -1;
      display: flex;
    }
  }

  .imgbox {
    margin: 0 20px;
    padding-top: 10px;

    >img {
      width: 100%;
      display: flex;
    }
  }

  .activityRules {
    position: relative;
    background-color: #fff;
    margin: 10px 15px;
    // padding-bottom: 1px;
    border-radius: 8px;

    .title {
      width: 100%;
      padding-top: 20px;
      text-align: center;
      color: #b2987f;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;
      padding-bottom: 5px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 70px;
        font-size: 12px;

        >span {
          text-align: center;
          position: absolute;
          top: 0px;
          left: 0;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;
        }
      }
    }

    table {
      width: calc(100% - 30px);
      margin: 0 15px;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0;
      background-color: var(--w-bgcolor);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-family: PingFang SC;
      border-bottom: 2px solid #e2d2bf;
      font-weight: 400;
      color: #684728;

      th,
      td {
        height: 40px;
        padding: 10px 0;
        color: var(--td-colortext);
        font-size: 13px;
        text-align: center;
      }
    }

    tr:nth-child(1) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr:nth-child(odd) {
      background-color: #f7f6f3;
    }

    td {
      border-right: 2px solid #e2d2bf;
    }

    td:nth-child(1) {
      border-left: 2px solid #e2d2bf;
    }
  }

  .Rules {
    margin: 10px 15px;
    // margin: 0 20px;
    // margin-top: 15px;
    font-family: PingFang SC;
    border-radius: 8px;
    background-color: #fff;

    .title {
      padding-top: 20px;
      width: 100%;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 18px;
        font-size: 13px;
        line-height: 1.5em;

        >span {
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
        }
      }
    }
  }
}

.btn-box {
  padding-bottom: 5px;

  >div {
    &:nth-child(1) {
      height: 36px;
      text-align: center;
      border-radius: 5px;
      background: url("https://begood.gz.bcebos.com/h5/webviewImg/pcbb/apply.png") no-repeat;
      background-size: 100% 100%;
      margin: 25px 20px 15px 20px;
    }

    &:nth-child(2) {
      height: 36px;
      text-align: center;
      border-radius: 5px;
      background: url("https://begood.gz.bcebos.com/h5/webviewImg/pcbb/apply2.png") no-repeat;
      background-size: 100% 100%;
      margin: 15px 20px 15px 20px;
    }
  }
}
</style>